
import { Vue, Options, Prop, Model } from 'vue-property-decorator';

import MaterialButton from '@/components/material/Button.vue';
import MaterialCard from '@/components/material/Card.vue';
import MaterialSlider from '@/components/material/Slider.vue';
import MaterialSwitch from '@/components/material/Switch.vue';

import { Stage } from '@/service/stage';

import locales from './Panel.locales.json';

export enum PanelCommand {
    cancel,
    clear,
    confirm,
    export,
    load,
    removeLast,
    reverse,
    save,
    selectEnd,
    selectNext,
    start,
}

@Options({
    components: {
        MaterialButton, MaterialCard, MaterialSlider, MaterialSwitch
    },
    emits: [ 'command' ],
    i18n: {
        messages: locales
    }
})
export default class Panel extends Vue {

    @Model('angleDiffThreshold', Number) readonly angleDiffThresholdValue!: number;
    @Model('autoConfirmNext', Boolean) readonly autoConfirmNextValue!: boolean;
    @Model('satelliteLayerOpacity', Number) readonly satelliteLayerOpacityValue!: number;
    @Prop(Number) readonly stage!: Stage;
    @Prop(Array) readonly trace! : Array<GeoJSON.Position>;

    showMore: boolean = false;

    get stageText(): string {
        switch (this.stage) {
            case Stage.idle:    return 'idle';
            
            case Stage.selectFirstLine: return 'selectFirstLine';
            case Stage.selectDirection: return 'selectDirection';
            
            case Stage.searchNext:  return 'searchNext';
            case Stage.selectNext:  return 'selectNext';
            case Stage.confirmNext: return 'confirmNext';

            case Stage.selectReversePosition:   return 'selectReversePosition';
            case Stage.confirmReversePosition:  return 'confirmReversePosition';

            case Stage.selectEnd:   return 'selectEnd';
            case Stage.confirmEnd:  return 'confirmEnd';

            case Stage.confirmClear:    return 'confirmClear';

            default:    return 'unknown';
        }
    }

    get moreLessButtonText() : string {
        return this.$t(this.showMore ? 'less' : 'more');
    }

    get idle() : boolean {
        return this.stage === Stage.idle;
    }

    get traceNotEmpty() :boolean {
        return this.trace.length > 0;
    }

    get confirming() : boolean {
        return this.stage === Stage.confirmNext
            || this.stage === Stage.confirmEnd
            || this.stage === Stage.confirmReversePosition
            || this.stage === Stage.confirmClear;
    }

    onClickCancel       = () => this.emitCommand(PanelCommand.cancel    );
    onClickClear        = () => this.emitCommand(PanelCommand.clear     );
    onClickConfirm      = () => this.emitCommand(PanelCommand.confirm   );
    onClickExport       = () => this.emitCommand(PanelCommand.export    );
    onClickLoad         = () => this.emitCommand(PanelCommand.load      );
    onClickRemoveLast   = () => this.emitCommand(PanelCommand.removeLast);
    onClickReverse      = () => this.emitCommand(PanelCommand.reverse   );
    onClickSave         = () => this.emitCommand(PanelCommand.save      );
    onClickSelectEnd    = () => this.emitCommand(PanelCommand.selectEnd );
    onClickSelectNext   = () => this.emitCommand(PanelCommand.selectNext);
    onClickStart        = () => this.emitCommand(PanelCommand.start     );

    onClickMoreLess() {
        this.showMore = !this.showMore;
    }

    private emitCommand(command: PanelCommand) {
        this.$emit('command', command);
    }
}
