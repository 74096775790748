
import { MDCSwitch } from '@material/switch';
import { Vue, Model, Watch } from 'vue-property-decorator';

export default class MaterialSwitch extends Vue {

    @Model('modelValue', { type: Boolean, default: false }) readonly value!: boolean;

    private ctrl?: MDCSwitch;

    @Watch('value')
    onValueChanged(newVal: boolean, _: boolean) {
        if (!this.ctrl) return;
        this.ctrl.selected = newVal;
    }

    mounted() {
        this.ctrl = MDCSwitch.attachTo(this.$el);
        this.ctrl.selected = this.value;
        this.ctrl.listen('click', this.onClick);
    }

    unmounted() {
        this.ctrl?.destroy();
    }

    private onClick() {
        if (!this.ctrl) return;
        this.$emit('update:modelValue', this.ctrl.selected);
    }
}
