
import { MDCSlider } from '@material/slider';
import { Vue, Model, Prop, Watch } from 'vue-property-decorator';

export default class MaterialSlider extends Vue {

    @Model('modelValue', { type: Number, default: 50 }) readonly value!: number;
    @Prop({ type: Number, default: 0 }) readonly min?: number;
    @Prop({ type: Number, default: 100 }) readonly max?: number;
    @Prop({ type: Number, default: 1 }) readonly step?: number;
    @Prop(String) readonly name?: string;
    @Prop(String) readonly label?: string;

    private ctrl?: MDCSlider;

    @Watch('value')
    onValueChanged(newVal: number, _: string) {
        if (!this.ctrl) return;
        this.ctrl.setValue(newVal);
    }

    mounted() {
        // Fix missing value
        (this.$el as HTMLDivElement).querySelector('input')!.setAttribute('value', `${this.value}`);
        this.ctrl = MDCSlider.attachTo(this.$el);
        this.ctrl.setValue(this.value);
        this.ctrl.listen('MDCSlider:change', this.onChange);
    }

    unmounted() {
        this.ctrl?.destroy();
    }

    private onChange() {
        if (!this.ctrl) return;
        this.$emit('update:modelValue', this.ctrl.getValue());
    }
}
