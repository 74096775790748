import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_trace_map = _resolveComponent("trace-map")!
  const _component_panel = _resolveComponent("panel")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_trace_map, {
      ref: "map",
      focuse: _ctx.focuse,
      selection: _ctx.selection,
      trace: _ctx.trace,
      "satellite-layer-opacity": _ctx.satelliteLayerOpacity,
      onSelect: _ctx.onSelect
    }, null, 8, ["focuse", "selection", "trace", "satellite-layer-opacity", "onSelect"]),
    _createVNode(_component_panel, {
      stage: _ctx.stage,
      trace: _ctx.trace,
      "angle-diff-threshold": _ctx.angleDiffThreshold,
      "onUpdate:angle-diff-threshold": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.angleDiffThreshold) = $event)),
      "auto-confirm-next": _ctx.autoConfirmNext,
      "onUpdate:auto-confirm-next": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.autoConfirmNext) = $event)),
      "satellite-layer-opacity": _ctx.satelliteLayerOpacity,
      "onUpdate:satellite-layer-opacity": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.satelliteLayerOpacity) = $event)),
      onCommand: _ctx.onCommand
    }, null, 8, ["stage", "trace", "angle-diff-threshold", "auto-confirm-next", "satellite-layer-opacity", "onCommand"])
  ]))
}